<template>
  <div>
    <custom-breadcrumb
      :pageTitle="$t('Reports')"
      :breadcrumb="[]"
    ></custom-breadcrumb>

  <b-card no-body>
    <template >
      <b-card-body>

        <b-card-title>{{ $t('Reports') }}</b-card-title>
        <b-card-sub-title>
          {{$t('reports.documents.documents_description1')}}
          <br>
          {{$t('reports.documents.documents_description2')}}
          <br>
          {{$t('reports.documents.documents_description3')}}
        </b-card-sub-title>
        <b-row>
          <b-col style="text-align:right">
            <b-button
              :disabled="loading"
              v-b-tooltip.hover.top="$t('reports.make_report')"
              variant="success"
              type="button"
              class="ml-1 mb-1"
              :to="{ name: 'reports-create' }"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            </b-button>
            <b-button
              :disabled="loading"
              variant="secondary"
              type="button"
              class="ml-1 mb-1"
              @click="getData"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
            <feather-icon
              icon="RotateCwIcon"
              size="16"
            />
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
        
      <paginated-table
        :data="documents"
        ability="Reports"
        link='report'
        :loading_data="loading"
        :canSelect="false"
        @delete="deleteModel"
      ></paginated-table>
      
    </template>
  </b-card>
  </div>
</template>

<script>
import { getDocumentsList, deleteDocument } from '@/api/reports'

import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow, BCol, BTable, BCardText, BButton, BCardBody, BCardSubTitle,
  BCard, BCardHeader, BCardTitle, BLink, BSpinner, VBTooltip
} from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
  name: "ReportsList",
  components:{
    CustomBreadcrumb, BCardBody,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardText,
    BCard, BCardHeader, BCardTitle, BLink, BSpinner, BCardSubTitle,
    PaginatedTable
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      documents: [],
      loading: false
    }
  },
  created(){
    this.getData()
  },
  methods:{
    deleteModel(document){
      this.$bvModal
        .msgBoxConfirm(this.$t('reports.documents.confirm_delete_document'), {
          title: this.$t('reports.documents.delete_document'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'user_id': this.$store.getters['user/getUserData'].id, 
              'lang' : this.$store.getters['user/getLang'],
              'token': this.$store.getters['user/getUserData'].sso,
              "id": document.id,
              "uuid": document.uuid,
            }
            this.$http.post(deleteDocument, data).then( response =>{
              // console.log(response, "delete:")
              if(response.data.status == 'deleted'){
                this.getData()
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }
            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    async getData(){
      this.loading = true

      let data = {
        'user_id': this.$store.getters['user/getUserData'].id, //322794, //this.currentUserId,
        'lang' : this.$store.getters['user/getLang'], //'es',//this.getLang,
        'token': this.$store.getters['user/getUserData'].sso,
      }
      await this.$http.post(getDocumentsList,data).then(response => {
        this.documents = response.data.documents
        this.loading = false
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
      
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
 
  }
}
</script>

<style>

</style>